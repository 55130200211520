.menu-option {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 10;

    font-size: 1.5rem;
    line-height: 1.5rem;
}

.section {
    position: relative;

    padding: 30px 0;
}

.section.section-gray {
    background-color: #f8f8f8;
}

.section > .container > :first-child {
    margin-top: 0px;
}

.section > .container > :last-child {
    margin-bottom: 0px;
}

.loading {
    position: fixed;
    z-index: 10;

    width: 100%;
    height: 100%;
}

.loading > .loading-screen {
    width: 100%;
    height: 100%;

    background-color: #fff;
    opacity: .6;
}

.loading > img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -32px;
    margin-left: -32px;
}

.card.clickable {
    cursor: pointer;
}

.card .request-ordinal {
    position: absolute;
    top: -1px;
    right: -1px;

    border-top: 1px solid rgba(0,0,0,0);
    border-right: 1px solid rgba(0,0,0,0);
    border-bottom: 1px solid rgba(0,0,0,.125);
    border-left: 1px solid rgba(0,0,0,.125);

    border-bottom-left-radius: .25rem;
    border-top-right-radius: .25rem;

    padding: .1rem .5rem;

    font-size: .9rem;
}

.options-menu {
    position: fixed;
    bottom: 0;
    z-index: 10;

    width: 100%;
    padding: 30px;
    min-height: 25%;
    border-top: solid 2px rgba(0,0,0,.125);

    background-color: #eee;
    opacity: .97;
}

.options-menu .section-close {
    position: absolute;
    top: 15px;
    right: 15px;

    line-height: 1.5rem;
    font-size: 1.5rem;
}

.menu-screen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;

    width: 100%;
    height: 100%;

    background-color: #fff;
    opacity: .6;
}